var root;
var loginModal;

$(function () {
    root = $('body').data('root');

    // client side error handling
    var errorTreshold = 0;
    var maxErrorTreshold = 10;

    window.onerror = function (errorMsg, url, lineNumber, columnNumber, errorObject) {
        if (errorTreshold++ > maxErrorTreshold) {
            return;
        }

        var arg = {
            message: errorMsg,
            url: url,
            line: lineNumber,
            column: columnNumber
        };
        if (errorObject) {
            arg.stack = errorObject.stack;
        }

        var xhr = new XMLHttpRequest();
        xhr.open('POST', root + '/Error/LogJavaScriptError');

        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(arg));
    };

    // run js if Chrome is being used - fix for the parallel banners for chrome
    if ('WebkitAppearance' in document.documentElement.style) {
        // fix webkit bug with background-attachment:fixed and transform applied, only first banner will be fixed.
        $(".w-slider-mask .banner:not(:first)").css('background-attachment', 'scroll');
    }


    //Overriding default options
    Lobibox.notify.DEFAULTS = $.extend({}, Lobibox.notify.DEFAULTS, {
        size: pf_MiniNotifySize,
        position: pf_MiniNotifyPosition,
        rounded: pf_MiniNotifyRoundedBorders,
        delay: pf_MiniNotifyCloseDelay,
        delayIndicator: false,
        sound: false,
        title: false
    });


    InitPage();
});

$(window).load(function () {

    // initialise page
    // lazy load any images
    $("img.lazy").each(function () {
        $(this).attr("src", $(this).attr("data-original")).fadeIn('slow');
        $(this).removeAttr("data-original");
    });

    $(document).on("scroll", function () {
        if ($(document).scrollTop() > 100) {
            $("header").addClass("shrink");
        }
        else {
            $("header").removeClass("shrink");
        }
    });

    $('.mbss-listItem2-wrapper').lightGallery({
        selector: '.videoPopBtn',
        loadYoutubeThumbnail: false,
        loadVimeoThumbnail: false,
        thumbnail: false,
        height: '100%',
        width: '100%',
        addClass: 'broadloomGallery',
        download: 'false',
        subHtmlSelectorRelative: true
    });
    $('.bespoke-img').lightGallery({
        selector: '.videoPopBtn',
        loadYoutubeThumbnail: false,
        loadVimeoThumbnail: false
    });
    $(document).on('click', '.iframe-modal-link', function () {
        var wrapper = $(this).closest('.redactor-in');
        if (wrapper.length == 0) {
            $iframeModal = $('[data-remodal-id=iframe-modal]').remodal({ 'hashTracking': false });
            $('#iframe-modal-content').html($(this).data('iframe'));
            $iframeModal.open();
        }
    });
    $(document).on('closed', '[data-remodal-id=iframe-modal]', function (e) {
        $('#iframe-modal-content').html('');
    });
    $('#gallery-image-holder').lightGallery({
        selector: '.gallery-media',
        loadYoutubeThumbnail: false,
        loadVimeoThumbnail: false
    });
    $('.pf-gallery-item').matchHeight();

    // generic platform modal - see global functions for it
    platformModal = $('[data-remodal-id=platformModal]').remodal({ 'hashTracking': false });

    $('[data-remodal-id=modal]').remodal(); // signup success modal

    $.scrollUp({
        scrollText: '<i class="fal fa-arrow-circle-up"></i>', // Set true to use image
    });
    //add data attributes to banner text
    $('.big-title-banner').data("ix", "fade-in-big-title-on-load");
    $('.sub-text-title').data("ix", "fade-in-sub-text-title-on-load");

    // site search handler - brochure
    $('#site-search-tbx').keypress(function (e) {
        if (e.which == 13) {
            $('#site-search-btn').click();
        }
    });
    $('#site-search-btn').click(function () {
        if ($('#site-search-tbx').val() != "") {
            var str = $('#site-search-tbx').val();
            window.location.href = root + "Home/SiteSearch?s=" + str;
        }
        return false;
    });

    // product search handler
    $("#prod-search-tbx").autocomplete({
        source: root + 'Products/AutoComplete',
        minLength: 2,
        select: function (event, ui) {
            window.location.href = ui.item.url;
        }
    });
    $('#prod-search-tbx').keypress(function (e) {
        if (e.which == 13) {
            $('#prod-search-btn').click();
        }
    });
    $('#prod-search-btn').click(function () {
        if ($('#prod-search-tbx').val() != "") {
            var str = $('#prod-search-tbx').val();
            window.location.href = root + "Products/Search?search=" + encodeURIComponent(str);
        }
        return false;
    });

    // expanded product search
    $('.search-opener').click(function () {
        $('.search-section').css('display', 'block');
        $('.search-bar').focus();
    });
    $('.search-close').click(function () {
        $('#expanded-search-tbx').val('');
        $('#search-results-wrapper').empty();
        $('#search-string-display').empty();
        $('.search-section').css('display', 'none');
    });

    var debounceSearchRequest = $.debounce(250, function () {
        const searchBox = $("#expanded-search-tbx");
        var searchUrl = searchBox.data("search-url");
        var searchStr = searchBox.val();
        searchUrl += "?search=" + encodeURIComponent(searchStr);

        gtag('config', $('#GoogleAnalyticsID').data('id'), {
            'page_title': 'Virtual search',
            'page_path': '/virtual/search?search=' + encodeURI(searchStr)
        });
        if (searchStr.length >= 2) {
            GetSearchResults(searchUrl);
            $('#search-string-display').html(searchStr);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'search',
                'searchTerm': encodeURI(searchStr)
            });
        } else {
            $('#search-string-display').html("");
            $('#search-results-wrapper').html("");
        }
    });

    $("#expanded-search-tbx").bind('input', function (e) {
        debounceSearchRequest();
    });

    function GetSearchResults(searchUrl, IsLoadMore) {
        $('#search-results-spinner-wrapper').show()
        var productIndex = $('.search-list-result-item').length;
        $.ajax({
            url: searchUrl,
            type: 'GET',
            contentType: 'application/json; charset=utf-8',
            success: function (data) {
                var $searchProductsWrapper = $('#search-results-wrapper');
                if (productIndex > 0 && IsLoadMore) {
                    var $newSearchProductsWrapper = $('<div>' + data + '</div>');
                    // Replace top sort & limit section
                    $searchProductsWrapper.find('.filter-select-wrapper').replaceWith($newSearchProductsWrapper.find('.filter-select-wrapper'));
                    // Replace load more container
                    $searchProductsWrapper.find('.pf-load-more-container').replaceWith($newSearchProductsWrapper.find('.pf-load-more-container'));
                    // Append new products to existing list
                    var $searchProductsContainer = $searchProductsWrapper.find('.results-wrapper');
                    var $newSearchProducts = $newSearchProductsWrapper.find('.results-wrapper > .search-list-result-item');
                    var newProductsReached = false;
                    for (var i = 0; i < $newSearchProducts.length; i++) {
                        var $newSearchProduct = $($newSearchProducts[i]);
                        if (!newProductsReached)
                            newProductsReached = $newSearchProduct.find('#sp' + (productIndex + 1)).length > 0;
                        if (newProductsReached)
                            $searchProductsContainer.append($newSearchProduct);
                    }
                } else {
                    $searchProductsWrapper.html(data);
                }
                $('#search-results-wrapper').waitForImages(function () {
                    $('.sub-cat-holder-match-height').matchHeight();
                    $('.product-info-match-height').matchHeight();
                });
                InitSearchResultEvents();
                $('#search-results-spinner-wrapper').hide()
            },
            error: function (msg) {
                alert('Sorry there was a problem processing your request.');
            }
        });
    }

    function InitSearchResultEvents() {
        $(".pf-load-more-search-results-btn").click(function () {
            const loadMoreUrl = $(this).data("url");
            GetSearchResults(loadMoreUrl, true);
        });
    }


    // bag handler
    $('#bag-opener').click(function () {
        switch (pf_DisplayCartOption) {
            case ("slide"): {
                SlideTopCart();
                break;
            }
            case ("popup"): {
                OpenCartPopup();
                break;
            }
            case ("page"): {
                window.location.href = root + "Checkout";
                break;
            }
        }
        return false;
    });

    $('#bag-closer').click(function () {
        SlideTopCart();
    });

    $(".sidenav-btn").click(function (event) {
        OpenSideNav();
    });

    // login modal trigger
    loginModal = $('#login-modal').remodal();
    $('.login-modal-btn').click(function () {
        loginModal.open();
    });
    // login from modal form
    $('#login-form-modal').submit(function () {
        $.ajax({
            url: $(this).attr('action'),
            type: $(this).attr('method'),
            dataType: 'json',
            data: $(this).serialize(),
            success: function (data) {
                if (data.result == "ok") {
                    if (data.redirecturl != "") {
                        window.location.href = data.redirecturl;
                    }
                    else {
                        window.location.reload();
                    }
                }
                else if (data.result == "fail") {
                    $('#modal-login-warning').fadeIn();
                }
                else {
                    alert("Error: " + data.error);
                }
            },
            error: function (xhr, err) {
                alert('Error: ' + err);
            }
        });
        return false;
    });

    var $cartChangesModal = $('#cart-changes-modal');
    if ($cartChangesModal.length > 0)
        $cartChangesModal.remodal().open();

    // Control RelatedProducts
    var relatedDiv = document.getElementById('RelatedRecentlyProducts');
    if (relatedDiv !== null) {

        var prodId = $(relatedDiv).data("prod-id");
        var showTabs = $(relatedDiv).data("show-tabs");
        var showRecently = $(relatedDiv).data("show-recently");
        var showRelated = $(relatedDiv).data("show-related");
        $('#RelatedRecentlyProducts').load(root + "Products/RelatedRecentlyProducts?prodID=" + prodId + "&showTabs=" + showTabs + "&showRecently=" + showRecently + "&showRelated=" + showRelated);
    }
});

// functions
function InitPage() {
    $('#top-cart-holder').hide();
    SetCartQty();
    LoadCart();
    CheckItems();
}

function CheckItems() {
    $.get(root + 'Checkout/CheckItemsCount', function (response) {
        var $proceedCheckoutButton = $('.pf-proceed-checkout-btn');
        if (response.hasItems)
            $proceedCheckoutButton.show();
        else
            $proceedCheckoutButton.hide();
    });
}

function GetCart() {
    LoadCart();
}

function SlideTopCart() {
    if ($('#top-cart-holder').is(':hidden')) {
        $('#top-cart-holder').slideDown();
    }
    else {
        $('#top-cart-holder').slideUp();
    }
}

function OpenCartPopup() {
    $('[data-remodal-id=cartModal]').remodal().open();
}

function GetAndOpenTopCart() {
    GetCart();
    if ($('#top-cart-holder').is(':hidden')) {
        $('#top-cart-holder').slideDown();
    }
}

function SetCartQty() {
    $.post(root + "Cart/GetNumCartItems", function (data) {
        if (data.result == "ok") {
            if (data.qty == 1) {
                $('.cart-total-qty').html(data.qty);
            }
            else {
                $('.cart-total-qty').html(data.qty);
            }
        }
    });
}

function OpenSideNav() {
    document.getElementById("navbackground").style.width = "100%";
    document.getElementById("sidenav").style.width = "80%";
    document.getElementById("navbackground").style.opacity = "0.8";
    // add close event
    $("#sidenav-close-btn").click(function (event) {
        CloseSideNav();
    })
    $("#navbackground").click(function () {
        CloseSideNav();
    })
}
function CloseSideNav() {
    document.getElementById("navbackground").style.opacity = "0";
    document.getElementById("sidenav").style.width = "0";
    document.getElementById("navbackground").style.width = "0";
}

function RegisterRsp(data) {
    if (data.result == "ok") {
        $('[data-remodal-id=modal]').remodal().open();
        $('#signup-tbx').val('');
    }
    else {
        $(".errorMessage").html(data.result);
        $("#emailError").remodal().open();

    }

}
function emailNotCorrect(data) {
    $('#emailNotCorrect').remodal().open();
}

// highlight html elements on change (.e.g cart totals)
jQuery.fn.highlight = function () {
    $(this).each(function () {
        var el = $(this);
        el.before("<div/>")
        el.prev()
            .width(el.width())
            .height(el.height())
            .css({
                "position": "absolute",
                "background-color": "#ffff99",
                "opacity": ".9"
            })
            .fadeOut(1000);
    });
}

// call from anywhere to load in the top cart
function LoadCart(callback) {

    switch (pf_DisplayCartOption) {
        case ("slide"): {
            $('.pf-top-cart-loader').load(root + 'cart/GetCart?forCheckout=true', function () {
                if (callback) {
                    callback();
                }
            });
            break;
        }
        case ("popup"): {
            $('.pf-popup-cart-loader').load(root + 'cart/GetCart?forCheckout=true', function () {
                if (callback) {
                    callback();
                }
            });
            break;
        }
        case ("page"): {
            $('.pf-page-cart-loader').load(root + 'cart/GetCart?forCheckout=true', function () {
                if (callback) {
                    callback();
                }
            });
            break;
        }
    }

}

function OpenTopCart() {
    if ($('#top-cart-holder').is(':hidden')) {
        $('#top-cart-holder').slideDown();
    }
}

// Platform Modal
function SetPlatformModalContent(title, text) {
    $('#platformModalTitle').html(title);
    $('#platformModalText').html(text);
}
function OpenPlatformModal(maxWidth) {
    if (maxWidth) {
        document.getElementById("platformModal").style.maxWidth = maxWidth;
    }
    else {
        document.getElementById("platformModal").style.maxWidth = "none";
    }
    platformModal.open();
}
function ClosePlatformModal() {
    platformModal.close();
}



function ImagehtmlFromUrl(url) {
    if (url == null || url == undefined || url == '' || url.endsWith('/product-images/')) {
        return '<img class="notification-image" src="/Content/site-images/master/default-placeholder.png" class="brand-filter-img">'
    }
    else {
        return '<img class="notification-image" src="' + url + '" class="brand-filter-img">'
    }
}
// Platform JS events
// product events
$(document).on('itemAdded.product.platform', function (event, data) {
    console.log("EVENT_AddToCart");
    // switch based on global var
    switch (pf_AddCartNotifyAction) {
        case ("toastr"): {
            switch (data.result) {
                case "multiple": {
                    if (data.addedUpdated > 0) {
                        Lobibox.notify('success', {
                            msg: data.addedUpdated + (data.addedUpdated === 1 ? ' product has' : ' products have') + ' been added to your ' + pf_CartLabel,
                            icon: 'fas fa-check-circle',
                            title: 'Added to ' + pf_CartLabel
                        });
                    }
                    if (data.limitedStock > 0 || data.outOfStock > 0) {
                        var warningTotal = data.limitedStock + data.outOfStock;
                        Lobibox.notify('warning', {
                            msg: warningTotal + (warningTotal === 1 ? ' product has' : ' products have') + ' limited stock',
                            icon: 'fas fa-info-circle',
                            title: pf_CartLabel + ' stock limitation'
                        });
                    }
                    if (data.priceUnavailable > 0 || data.otherError > 0) {
                        var errorTotal = data.priceUnavailable + data.otherError;
                        Lobibox.notify('error', {
                            msg: errorTotal + (errorTotal === 1 ? ' product' : ' products') + ' could not be added to your ' + pf_CartLabel,
                            icon: 'fas fa-exclamation-circle',
                            title: pf_CartLabel + ' errors'
                        });
                    }
                    LoadCart();
                    SetCartQty();
                    CheckItems();
                    break;
                }
                case ("ok"): {
                    console.log(data);
                    Lobibox.notify('success', {
                        msg: ImagehtmlFromUrl(data.productImage)
                            + "<h1 class='pf-product-details-heading'>" + data.productTitle + "</h1>Has been added to your " + pf_CartLabel + ' <button id="bag-opener-notification" href="#" class="w-inline-block bag-opener pf-btn pf-btn-sm pf-btn-primary">Proceed to ' + pf_CartLabel + '</button>',
                        iconClass: false,
                        title: 'Added to ' + pf_CartLabel,
                        closeOnClick: false
                    });

                    $('#bag-opener-notification').click(function () {
                        switch (pf_DisplayCartOption) {
                            case ("slide"): {
                                SlideTopCart();
                                break;
                            }
                            case ("popup"): {
                                OpenCartPopup();
                                break;
                            }
                            case ("page"): {
                                window.location.href = root + "Checkout";
                                break;
                            }
                        }
                        return false;
                    });
                    LoadCart();
                    SetCartQty();
                    CheckItems();
                    break;
                }
                case ("ok-stock-limited"): {
                    //toastr["success"]("Sorry there are limited stock '" + data.productTitle + "'. We have added " + data.qty + " to your " + pf_CartLabel);
                    alert("Sorry there are limited stock '" + data.productTitle + "'. We have added " + data.qty + " to your " + pf_CartLabel);
                    LoadCart();
                    SetCartQty();
                    CheckItems();
                    break;
                }
                case ("ok-out-of-stock"): {
                    //toastr["warning"]("Sorry this item " + "'" + data.productTitle + "' is no longer in stock.");
                    alert("Sorry this item " + "'" + data.productTitle + "' is no longer in stock.");
                    LoadCart();
                    SetCartQty();
                    CheckItems();
                    break;
                }
                case ("unauthorised"): {
                    window.location.href = root + 'UserAccount/Login';
                    break;
                }
                case ("fail"): {
                    //toastr["error"]("Sorry an error has occured. " + data.err);
                    alert("Sorry an error has occurred. " + data.err);
                    break;
                }
            }
            break;
        }
        case ("slidetop"): {
            switch (data.result) {
                case "multiple": {
                    LoadCart();
                    OpenTopCart();
                    SetCartQty();
                    CheckItems();
                    $("html, body").animate({ scrollTop: 0 }, "slow"); // scroll to top
                    break;
                }
                case ("ok"): {
                    LoadCart();
                    OpenTopCart();
                    SetCartQty();
                    CheckItems();
                    $("html, body").animate({ scrollTop: 0 }, "slow"); // scroll to top
                    break;
                }
                case ("ok-stock-limited"): {
                    alert("Sorry there are only " + data.qty + " of these items available in stock.");
                    LoadCart();
                    OpenTopCart();
                    SetCartQty();
                    CheckItems();
                    $("html, body").animate({ scrollTop: 0 }, "slow"); // scroll to top
                    break;
                }
                case ("ok-out-of-stock"): {
                    alert("Sorry this item is no longer in stock.");
                    LoadCart();
                    SetCartQty();
                    CheckItems();
                    break;
                }
                case ("unauthorised"): {
                    window.location.href = root + 'UserAccount/Login';
                    break;
                }
                case ("fail"): {
                    alert("Sorry an error has occured. " + data.err);
                    break;
                }
            }
            break;
        }
    }
});


// cart events
$(document).on('itemRemoved.cart.platform', function (event, data) {
    switch (pf_AddCartNotifyAction) {
        case ("toastr"): {


            LoadCart();
            SetCartQty();
            CheckItems();

            break;
        }
        default: {
            LoadCart();
            SetCartQty();
            CheckItems();
            break;
        }
    }
});


$(document).on('itemUpdated.cart.platform', function (event, data) {
    switch (data.result) {
        case ("ok"): {
            SetCartQty();
            LoadCart(function () {
                //$(".pf-top-cart-loader .checkout-cart-footer-row").highlight();
            });
            break;
        }
        case ("ok-stock-limited"): {
            alert("Sorry there are limited stock '" + data.productTitle + "'. We have added " + data.qty + " to your " + pf_CartLabel);
            LoadCart(function () {
                //$(".pf-top-cart-loader .checkout-cart-footer-row").highlight();
            });
            SetCartQty();
            break;
        }
        case ("ok-out-of-stock"): {
            alert("Sorry this item " + "'" + data.productTitle + "' is no longer in stock.");
            LoadCart(function () {
                //$(".pf-top-cart-loader .checkout-cart-footer-row").highlight();
            });
            SetCartQty();
            break;
        }
        case ("unauthorised"): {
            window.location.href = root + 'UserAccount/Login';
            break;
        }
        case ("fail"): {
            alert("Sorry an error has occurred. " + data.err);
            break;
        }
    }
});



// Favourites events
$(document).on('addedToFavs.product.platform', function (event, data) {
    switch (pf_AddCartNotifyAction) {
        case ("toastr"):
            {
                switch (data.result) {
                    case "multiple": {
                        Lobibox.notify('info', {
                            msg: 'Products added to the selected wish list(s)',
                            icon: 'fas fa-heart',
                            title: 'Quote List Changes Saved'
                        });
                        break;
                    }
                    case ("ok"): {
                        Lobibox.notify(
                            'info',  // Available types 'warning', 'info', 'success', 'error'
                            {
                                //msg: ImagehtmlFromUrl(data.productImage)
                                msg: "<h1 class='pf-product-details-heading'>" + data.productTitle + "</h1> Quote list changes saved <button id='quote-opener-notification' href='#' class='w-inline-block pf-btn pf-btn-sm pf-btn-primary'>Proceed to Quotes</button>",
                                icon: 'fas fa-heart',
                                title: "Quote List Changes Saved"
                            }
                        );

                        QuoteOpenerEvent();

                        break;
                    }
                }

                break;
            }

    }
});

$(document).on('removeWishList.product.platform', function (event, data) {
    switch (pf_AddCartNotifyAction) {
        case ("toastr"):
            {
                switch (data.result) {
                    case ("ok"): {
                        Lobibox.notify(
                            'info',  // Available types 'warning', 'info', 'success', 'error'
                            {
                                msg: "<h1 class='pf-product-details-heading'>" + data.wishlistName + "</h1> removed",
                                icon: 'fas fa-heart',
                                title: "Quote List removed"
                            }
                        );
                        break;
                    }
                }

                break;
            }

    }
});

$(document).on('removedFromFavs.product.platform', function (event, data) {
    switch (pf_AddCartNotifyAction) {
        case ("toastr"):
            {
                switch (data.result) {
                    case ("ok"): {
                        Lobibox.notify(
                            'info',  // Available types 'warning', 'info', 'success', 'error'
                            {
                                msg: "<h1 class='pf-product-details-heading'>" + data.productTitle + "</h1> Has been removed from all Quote Lists<button id='quote-opener-notification' href='#' class='w-inline-block pf-btn pf-btn-sm pf-btn-primary'>Proceed to Quotes</button>",
                                icon: 'far fa-heart',
                                title: "Removed from all Quote Lists"
                            }
                        );

                        QuoteOpenerEvent();
                        break;
                    }
                }

                break;
            }

    }
});

function QuoteOpenerEvent() {
    $('#quote-opener-notification').click(function () {
        window.location.href = root + "/UserAccount/MyFavourites";
    });
}

//Platform.Deferred.execute(SetupSlider);

//// dependent on Slick JS plugin and called via deffered in slider widget - https://kenwheeler.github.io/slick/
//function SetupSlider(elementClass) {
//    $('.' + 'slider').slick({initialSlide: 0});
//}